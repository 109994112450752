
$primary:       #1a4677 !default;
$secondary:     #5c5c5c !default;

$primary-color: $primary;

$primary-bright: #ffffff !default;
$secondary-bright: #ffffff !default;

// Gradient colors
$primary-gradient: #1a4677 !default;
$primary-gradient-state: #5c5c5c !default;

$secondary-gradient:          #627594 !default;
$secondary-gradient-state:    #A8B8D8 !default;

$info-gradient:               $primary-gradient;
$info-gradient-state:         $primary-gradient-state;

$success-gradient:            #17ad37 !default;
$success-gradient-state:      #98ec2d !default;

$danger-gradient:             #ea0606 !default;
$danger-gradient-state:       #ff6075 !default;

$warning-gradient:            #ff8f8f !default;
$warning-gradient-state:      #f8ea7a !default;

$dark-gradient:               #141727 !default;
$dark-gradient-state:         #3A416F !default;

$light-gradient:              #CED4DA !default;
$light-gradient-state:        #EBEFF4 !default;

$dark-gradient-dark:          #323a54 !default;
$dark-gradient-state-dark:    #1a2035 !default;

$blur-border-radius-rounded:    10px !default;

// text gradient
$text-gradient-bg-primary:    linear-gradient(310deg, $primary-gradient, $primary-gradient-state) !default;


$fa-font-path:         "../fonts/webfonts" !default;


$theme-gradient-colors: (
        "primary":    ($primary-gradient, $primary-gradient-state),
        "secondary":  ($secondary-gradient, $secondary-gradient-state),
        "success":    ($success-gradient, $success-gradient-state),
        "info":       ($info-gradient, $info-gradient-state),
        "warning":    ($warning-gradient, $warning-gradient-state),
        "danger":     ($danger-gradient, $danger-gradient-state),
        "light":      ($light-gradient, $light-gradient-state),
        "dark":       ($dark-gradient, $dark-gradient-state)
) !default;
