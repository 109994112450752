//@import "../../bootstrap/mixins/border-radius";

.input-group {
  @include border-radius($input-border-radius, 0);

  &,
  .input-group-text {
    transition: $input-transition;
  }

  & > :not(:first-child):not(.dropdown-menu) {
    margin-left: 0;
  }

	.form-control {
    &:focus {
      border-left: $input-border-width solid $input-focus-border-color !important;
      border-right: $input-border-width solid $input-focus-border-color !important;
    }

		&:not(:first-child) {
	    border-left: 0;
	    padding-left: 0;
		}
		&:not(:last-child) {
			border-right: 0;
			padding-right: 0;
		}

    & + .input-group-text {
      position: absolute;
      border-left: 0;
      border-right: $input-border-width solid $input-border-color;
    }
	}

  .input-group-text {
    border-right: 0;
  }
}

.form-group {
  margin-bottom: 1rem;
}

.input-group-text {
  border-right: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.form-control {
  border-radius: $input-border-radius;
  border-top-right-radius: $input-border-radius !important;
  border-bottom-right-radius: $input-border-radius !important;
  border-left-width: 1px;
}

.focused {
  box-shadow: $input-focus-box-shadow;
  transition: $input-transition;

  .form-control {
    &:focus {
      box-shadow: none;
    }
    + .input-group-text {
      border-color: $input-focus-border-color;
      border-right: $input-border-width solid $input-focus-border-color !important;
    }
  }
  .input-group-text {
    border-color: $input-focus-border-color;
  }
}
