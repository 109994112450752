@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 200;
  src: local('Open Sans'), local('Open-Sans'),
  url(/fonts/OpenSans/OpenSans.ttf) format('truetype'),
  url(/fonts/OpenSans/OpenSans.woff2) format('woff2'),
  url(/fonts/OpenSans/OpenSans.eot) format('eot'),
  url(/fonts/OpenSans/OpenSans.woff) format('woff');
}


