.logo{

  &-large {
    
    font-weight: bold;
    //color: $primary-bright;
    
    .logo-icon {
      font-size: 140px;
    }

    .logo-text {
        display: inline-block;
        font-size: 48px;
        font-weight: bolder;
    }

    .logo-sub-text {
      display: block;
      line-height: 20px;
      font-size: 68px;
      font-weight: normal; 
    }

  }

  &-small {
        
    .logo-icon {
      //color: $secondary-bright;
      font-size: 50px;
    }

    .logo-text {
        display: inline-block;
        //color: $secondary-bright;
        font-size: 20px;
        font-weight: bolder;
    }

    .logo-sub-text {
      display: block;
      //color: $secondary-bright;
      line-height: 15px;
      font-size: 24px;
      font-weight: normal; 
    }

  }

  &-scale {
    
    //color: $primary-color;

    .logo-icon {
      font-size: 36px;
    }

    .logo-text {
      margin: 2px;
      display: inline-block;
      font-size: 18px; /* 12px */
    }

    .logo-sub-text {
      display: block;
      line-height: 8px;
      font-size: 18px; /* 15px */
    }
  }

}
